// App - Types
import type { EntityType } from '@/types/entity';

export const INVENTORY_ROUTES = {
  root: '/inventory',

  inventory: () => `${INVENTORY_ROUTES.root}`,
  workspace: (entityType: EntityType, collectionId: string) =>
    `${INVENTORY_ROUTES.inventory()}/${entityType}/${collectionId}`,
};

// export const INVENTORY_QUERY_PARAMS = {
//   create: 'create',
// };
